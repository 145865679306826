<template>
    <ckeditor
        v-model="value"
        :editor="editor"
        :config="editorConfig"
        @input="onDataChange"
    />
</template>

<script>
/**
 * Para alterar algo do ckeditor, deve ser feito uma build no site https://ckeditor.com/ckeditor-5/online-builder/
 * Pra saber a base do que já existe de componentes do CKEditor, ver os imports usados em [pasta_do_ClassicEditor]/src/ckeditor.js
 * Depois, esta build deve ser descompactada em alguma pasta, e o import do ClassicEditor deve apontar para a mesma;
 * O ideal é rodar novamente o npm install no front-end e rodar novamente a build do front pra garantir que vai pegar tudo que foi alterado
 * OBS.: Se for inserido ou excluido alguma funcionalidade, deve ser inserida ou retirada do array editorConfig.toolbar[]
 * Nem tudo que está no import é usado, tem que ser colocado/tirado nesse array pra realmente aparecer ou não no editor
 */
    import CKEditor from "@ckeditor/ckeditor5-vue2";
    import ClassicEditor from "./ckeditor5-31.0.0-custom-build";
    export default {
        components: {
            ckeditor: CKEditor.component,
        },

        props: {
            value: {
                type: String,
                required: false,
                default: '',
            },
        },
        data() {
            const editorConfigObj = {
                    toolbar: [
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "blockQuote",
                        "link",
                        "imageUpload",
                        "highlight",
                        'fontsize',
                        "|",
                        "undo",
                        "redo",
                    ],
                    simpleUpload: {
                        uploadUrl: process.env.VUE_APP_API_BASE_URL + "questaos/image-upload/0",
                        headers: {
                            Authorization: this.$http.headers.common["Authorization"],
                        },
                    },
                    disableNativeSpellChecker: false
                }
            console.log(editorConfigObj);
            return {
                editor: ClassicEditor,
                editorConfig: editorConfigObj
            };
        },
        methods: {
            onDataChange() {
                this.$emit('input', this.value);
            }
        }
    }
</script>
<style scoped>
.ck-editor__editable {
  min-height: 5000px;
}
</style>